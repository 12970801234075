import request from './services/axios.js'

//邮件推送 -- 白名单管理相关接口
//获取白名单列表
export function GetAuthorListByPage(params) {
  return request({
    params,
    url: '/vas/author/import/list',
    method: 'get'
  })
}
//根据所在机构统计学者
export function StatisticsByOrg(params) {
  return request({
    params,
    url: '/vas/author/import/statisticsByOrg',
    method: 'get'
  })
}
//白名单学者分布
export function ByTerritory(params) {
  return request({
    params,
    url: '/vas/author/import/statisticsByTerritory',
    method: 'get'
  })
}
// 白名单列表下载
export const downloadList = PROJECT_CONFIG.BASE_URL + '/vas/author/import/export'
//白名单下载模板
export const DownloadTemplate = PROJECT_CONFIG.BASE_URL + '/vas/author/import/importTemplateDownload'
//白名单导入模板
export const ImportTemplate = PROJECT_CONFIG.BASE_URL + '/vas/author/import/import'
//白名单批量删除
export function DeleteAuthor(params) {
  return request({
    params,
    url: '/vas/author/import/deleteById',
    method: 'get'
  })
}

