<!-- newApply -->
<template>
  <div class="newApply salesHeader-box">
    <div class="salesHeader-content">
      <el-form ref="newApplyForm" :model="newApplyForm" :rules="active == 0 ? nextStepRules : newApplyRules" status-icon label-width="190px" class="new-apply-form">
        <el-form-item label="客户需求：" label-width="100px" prop="serviceStatusArr" v-if="isFromPm || active == 0">
          <el-checkbox-group v-model="newApplyForm.serviceStatusArr">
            <el-checkbox :label="1" :disabled="dialogStatus === 'watching' || isFromPm">邮件传播</el-checkbox>
            <el-checkbox :label="2" :disabled="dialogStatus === 'watching' || isFromPm">微信传播</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="运营负责人" label-width="100px" prop="operateMemberName">
          <el-select placeholder="请选择" v-model="newApplyForm.operateMemberName" :disabled="dialogStatus === 'watching' || isFromPm">
            <el-option v-for="(item, index) in operateMemberList" :key="index" :label="item.NAME" :value="item.NAME" />
          </el-select>
        </el-form-item>
        <el-steps :active="active" :finish-status="finishStatus" align-center v-if="!isFromPm">
          <el-step title="基本信息"> </el-step>
          <el-step title="配置信息"> </el-step>
        </el-steps>
        <div v-if="isFromPm || active == 0">
          <h3 v-if="!isFromPm">请填写基本信息</h3>
          <p class="tip" v-if="!isFromPm">
            填写前请知晓： 通常情况下，微网刊和邮件推送都会将浏览数据导流到官网。如果期刊没有官网，请填写发表了期刊文章的期刊主页地址，都没有则无需填写，不填写不支持邮件传播。
            如果没有填写期刊官网地址或官网移动端阅读体检不佳，但是使用了方正的生产平台，微网刊服务依然可提供并能保证良好的阅读体验
          </p>
          <div :class="[isDisabled]">
            <el-form-item label="期刊名称：" prop="magName" size="zdy" style="width: 450px">
              <el-autocomplete
                class="code-el-input"
                :disabled="isFromPm"
                v-model="newApplyForm.magName"
                value-key="magName"
                :fetch-suggestions="querySearchAsync"
                :trigger-on-focus="false"
                :rules="rules"
                placeholder="请输入内容"
                @select="selectMagList"
                @change="selectBlur"
              >
                <template v-slot="{ item }">
                  <span>{{ item.magName }}</span>
                </template>
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="期刊官网地址：" size="zdy" prop="offUrl">
              <el-input placeholder="该地址为论文数据采集地址，也是邮件/微网刊中点击论文后跳转的地址" class="code-el-input" :disabled="isFromPm" v-model="newApplyForm.offUrl" />
            </el-form-item>
            <el-form-item label="期刊介绍：" size="editor" prop="magInfo">
              <editor tinymce-script-src="../../../js/tinymce/tinymce.min.js" :disabled="isFromPm" :init="init" model-events="change keydown blur focus paste" v-model="newApplyForm.magInfo" />
              <p class="tip">
                说明：期刊介绍内容请从刊社老师处获取。如有段落请严格分段，若需要首段缩进2字符，在全角状态下输入两个空格。
              </p>
            </el-form-item>
          </div>
          <div class="next-button" v-if="!isFromPm">
            <el-button size="small" @click="nextStep" type="primary">下一步<i class="el-icon-arrow-right el-icon--right"></i></el-button>
          </div>
        </div>
        <div v-if="isFromPm || active == 1">
          <h3 class="mb-20" v-if="!isFromPm">请填写配置相关信息</h3>
          <div :class="[isDisabled]">
            <el-form-item label="选择邮件传播样式：" prop="emailTemplateStyleArr" v-if="showEmailSetting" class="emailStyleSetting">
              <el-checkbox-group v-model="newApplyForm.emailTemplateStyleArr">
                <div class="checkItem">
                  <el-checkbox :label="1" :disabled="isFromPm">样式一</el-checkbox>
                  <el-button @click="toEmailUrl(1)">查看样式</el-button>
                </div>
                <div class="checkItem">
                  <el-checkbox :label="2" :disabled="isFromPm">样式二</el-checkbox>
                  <el-button @click="toEmailUrl(2)">查看样式</el-button>
                </div>
                <div class="checkItem">
                  <el-checkbox :label="4" :disabled="isFromPm">样式三</el-checkbox>
                  <el-button @click="toEmailUrl(4)">查看样式</el-button>
                </div>
                <div class="checkItem">
                  <el-checkbox :label="5" :disabled="isFromPm">样式四</el-checkbox>
                  <el-button @click="toEmailUrl(5)">查看样式</el-button>
                </div>
              </el-checkbox-group>
              <p class="tip">请选择1-2个样式，多模板发送能提高邮件的到达率</p>
            </el-form-item>
            <el-form-item label="选择微网刊模板样式：" prop="templateType" v-if="showWXSetting">
              <el-radio-group v-model="newApplyForm.templateType">
                <div class="style-setting">
                  <div class="style-setting-item">
                    <el-radio :label="1" :disabled="dialogStatus === 'watching' || isFromPm">
                      样式一<br />
                      <el-popover placement="bottom" popper-class="newApply-popper" width="172" trigger="click" @show="showURL('yg')">
                        <div class="btn_fx">
                          <el-button round @click="previewStyle('yg')">预览</el-button>
                          <div id="qrcodeYG"></div>
                        </div>
                        <el-button slot="reference">查看样式</el-button>
                      </el-popover>
                    </el-radio>
                  </div>
                  <div class="style-setting-item">
                    <el-radio :label="2" :disabled="dialogStatus === 'watching' || isFromPm">
                      样式二<br />
                      <el-popover placement="bottom" popper-class="newApply-popper" width="172" trigger="click" @show="showURL('sk')">
                        <div class="btn_fx">
                          <el-button round @click="previewStyle('sk')">预览</el-button>
                          <div id="qrcodeSK"></div>
                        </div>
                        <el-button slot="reference">查看样式</el-button>
                      </el-popover>
                    </el-radio>
                  </div>
                </div>
              </el-radio-group>
            </el-form-item>
            <div v-if="showWXSetting || (showEmailSetting && newApplyForm.emailTemplateStyleArr.length > 0)">
              <div class="code-box" v-if="showWechatCode">
                <el-form-item label="上传该期刊公众号二维码：" prop="leftWechatPicId" :class="[isPmDisabled]">
                  <div class="code-item">
                    <img class="fl" :src="leftCodeSrc" alt />
                    <el-upload class="upload-demo" :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" :auto-upload="true" :on-success="handleLeftCodeSuccess">
                      <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
                    </el-upload>
                  </div>
                </el-form-item>
                <el-form-item label="对应的二维码名称：" prop="leftWechatName" v-if="newApplyForm.emailTemplateStyleArr.indexOf(5) === -1">
                  <el-input style="width: 200px" :disabled="isFromPm" placeholder="请输入" v-model="newApplyForm.leftWechatName" type="text" />
                </el-form-item>
              </div>
              <div v-if="showWXSetting">
                <div class="code-box">
                  <el-form-item label="其他二维码：" prop="rightWechatPicId" :class="[isPmDisabled]">
                    <div class="code-item">
                      <img class="fl" :src="rightCodeSrc" alt />
                      <el-upload
                        class="upload-demo"
                        :action="UploadPictureStream"
                        name="file"
                        :show-file-list="false"
                        accept=".png,.jpg,.jpeg"
                        :auto-upload="true"
                        :on-success="handleRightCodeSuccess"
                      >
                        <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
                      </el-upload>
                    </div>
                  </el-form-item>
                  <el-form-item label="对应的二维码名称：" prop="rightWechatPicName">
                    <el-input style="width: 200px" :disabled="isFromPm" placeholder="请输入" v-model="newApplyForm.rightWechatPicName" type="text" />
                  </el-form-item>
                </div>
                <p class="tip code-tip">
                  说明：若该期刊有其他相关的二维码也支持展示在微网刊中，如期刊中心、学会、B站等的二维码，没有则不填写
                </p>
              </div>
              <div v-if="showLogo">
                <el-form-item label="上传公众号Logo：" prop="logoPicId" :class="[isPmDisabled]">
                  <div class="code-box">
                    <div class="code-item">
                      <img class="fl" :src="logoSrc" alt />
                      <el-upload class="upload-demo" :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" :auto-upload="true" :on-success="handleLogoCodeSuccess">
                        <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
                      </el-upload>
                    </div>
                  </div>
                  <p class="tip">说明：请在该期刊公众号中查找</p>
                </el-form-item>
              </div>
              <el-form-item label="上传白名单：" v-if="showEmailSetting">
                <div class="white-export">
                  <el-upload
                    ref="importFile"
                    class="upload-demo"
                    :on-change="handleUploadChange"
                    :show-file-list="true"
                    :file-list="fileList"
                    :action="ImportTemplate"
                    :data="uploadObj"
                    accept=".xlsx"
                    :auto-upload="false"
                    :on-success="handleUploadSuccess"
                    :disabled="isFromPm"
                  >
                    <el-button :disabled="isFromPm"><i class="el-icon-upload2"></i> 上传文件</el-button>
                  </el-upload>
                  <a class="download-sample" href="#" @click="handleExportDownloadSample">下载模板</a>
                </div>
                <p class="tip">
                  请按模板填写后上传，邮箱为必填项，其他未填写的信息无需删除表头，支持扩展名：.xls, .xlsx
                </p>
              </el-form-item>
              <el-form-item label="备注：" size="zdy" prop="remark">
                <el-input class="code-el-input" :disabled="isFromPm" v-model="newApplyForm.remark" />
              </el-form-item>
            </div>
            <!-- <el-form-item label="公众号历史消息链接：" prop="wechatNewUrl">
                <el-input :disabled="isFromPm" class="code-item-text" placeholder="请在该期刊公众号中查找，如下图示例" v-model="newApplyForm.wechatNewUrl" type="text" />
                <p class="tip">说明：请在该期刊公众号中查找，PC端操作如下图示例，该链接用于微网刊当期/过刊目录中logo的跳转</p>
                <el-image class="history-link-img" :src="historyLinkImg" :preview-src-list="historyLinkImgList" alt=""></el-image>
              </el-form-item> -->
          </div>
          <div class="sales-apply-btns" v-if="!isFromPm">
            <el-button size="small" type="primary" icon="el-icon-arrow-left" @click="lastStep">上一步</el-button>
            <el-button type="primary" v-if="dialogStatus !== 'watching'" @click="submit('submit')">提交</el-button>
            <el-button type="success" v-if="dialogStatus !== 'watching'" @click="submit('save')">保存</el-button>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { GetPictureStream, UploadPictureStream,uploadEditorImg } from '@/api/configManage'
import { NewEditInfo, GetQueryMagList, GetMagList, GetOperateMemberList } from '@/api/roleManagement'
import Editor from '@tinymce/tinymce-vue'
import QRCode from 'qrcodejs2'
import { WeChatAllJumpUrl } from '@/assets/js/util'
import { DownloadTemplate, ImportTemplate } from '@/api/whiteList'
export default {
  name: 'newApply',
  data() {
    const validateMagName = (rule, value, callback) => {
      if (!this.newApplyForm.magId) {
        callback(new Error('请选择期刊'))
      } else callback()
    }
    return {
      uploadEditorImg,
      ImportTemplate: ImportTemplate,
      GetPictureStream: GetPictureStream,
      UploadPictureStream: UploadPictureStream,
      morImg: require('@/assets/img/img_default.png'),
      historyLinkImg: require('@/assets/img/historyLinkImg.png'),
      historyLinkImgList: [require('@/assets/img/historyLinkImg.png')],
      init: {
        min_height: 400,
        toolbar: false, //隐藏工具栏
        menubar: false, //隐藏菜单栏
        language: 'zh_CN',
        skin: 'CUSTOM'
      },
      newApplyForm: {
        magName: '',
        magId: '',
        offUrl: '',
        magInfo: '',
        remark: '',
        templateType: '',
        leftWechatPicId: '',
        rightWechatPicId: '',
        leftWechatName: '',
        rightWechatPicName: '',
        logoPicId: '',
        wechatNewUrl: '',
        serviceStatusArr: [],
        serviceStatus: '',
        emailTemplateStyleArr: [],
        emailTemplateStyle: '',
        operateMemberName: ''
      },
      rules: {
        magName: [{ message: '请选择期刊', trigger: 'blur,change', validator: validateMagName }]
      },
      nextStepRules: {
        serviceStatusArr: [{ message: '请选择客户需求', trigger: 'change', required: true }],
        operateMemberName: [{ message: '请选择运营负责人', trigger: 'change', required: true }],
        magName: [{ message: '请选择期刊', trigger: 'blur', required: true }],
        magId: [{ message: '请选择期刊', trigger: 'blur', required: true }],
        offUrl: [{ message: '期刊官网地址', trigger: 'blur', required: true }],
        magInfo: [{ message: '请填写期刊介绍', trigger: 'blur', required: true }]
      },
      // wechatNewUrl: [{ message: '请填写公众号历史消息链接', trigger: 'blur', required: true }]
      newApplyRulesWX: {
        templateType: [{ message: '请选择微网刊模板样式', trigger: 'change', required: true }],
        leftWechatPicId: [{ message: '请上传期刊公众号二维码', trigger: 'change', required: true }],
        leftWechatName: [{ message: '请填写二维码名称', trigger: 'blur', required: true }],
        logoPicId: [{ message: '请上传公众号Logo', trigger: 'change', required: true }]
      },
      newApplyRulesEmail1: {
        emailTemplateStyleArr: [{ message: '请选择邮件传播样式', trigger: 'change', required: true }]
      },
      newApplyRulesEmail2: {
        emailTemplateStyleArr: [{ message: '请选择邮件传播样式', trigger: 'change', required: true }],
        leftWechatPicId: [{ message: '请上传期刊公众号二维码', trigger: 'change', required: true }],
        leftWechatName: [{ message: '请填写二维码名称', trigger: 'blur', required: true }]
      },
      newApplyRulesEmail3: {
        emailTemplateStyleArr: [{ message: '请选择邮件传播样式', trigger: 'change', required: true }],
        leftWechatPicId: [{ message: '请上传期刊公众号二维码', trigger: 'change', required: true }],
        leftWechatName: [{ message: '请填写二维码名称', trigger: 'blur', required: true }],
        logoPicId: [{ message: '请上传公众号Logo', trigger: 'change', required: true }]
      },
      newApplyRulesEmail4: {
        emailTemplateStyleArr: [{ message: '请选择邮件传播样式', trigger: 'change', required: true }],
        templateType: [{ message: '请选择微网刊模板样式', trigger: 'change', required: true }],
        leftWechatPicId: [{ message: '请上传期刊公众号二维码', trigger: 'change', required: true }],
        leftWechatName: [{ message: '请填写二维码名称', trigger: 'blur', required: true }],
        logoPicId: [{ message: '请上传公众号Logo', trigger: 'change', required: true }]
      },
      leftCodeCover: '',
      rightCodeCover: '',
      logoCover: '',
      active: 0,
      finishStatus: 'process',
      queryMagList: [],
      tableData: [],
      fileList: [],
      operateMemberList: []
    }
  },
  props: {},
  components: { Editor },
  computed: {
    dialogStatus() {
      return this.$route.query.status
    },
    //左侧微信二维码
    leftCodeSrc() {
      return this.leftCodeCover ? this.leftCodeCover : this.newApplyForm.leftWechatPicId ? `${this.GetPictureStream}?pictureId=${this.newApplyForm.leftWechatPicId}` : this.morImg
    },
    // 右侧微信二维码
    rightCodeSrc() {
      return this.rightCodeCover ? this.rightCodeCover : this.newApplyForm.rightWechatPicId ? `${this.GetPictureStream}?pictureId=${this.newApplyForm.rightWechatPicId}` : this.morImg
    },
    // logo图
    logoSrc() {
      return this.logoCover ? this.logoCover : this.newApplyForm.logoPicId ? `${this.GetPictureStream}?pictureId=${this.newApplyForm.logoPicId}` : this.morImg
    },
    // 查看禁用编辑
    isDisabled() {
      return this.dialogStatus === 'watching' ? 'is-disabled' : ''
    },
    isPmDisabled() {
      return this.isFromPm ? 'is-disabled' : ''
    },
    YGUrl() {
      return WeChatAllJumpUrl(11414, 583)
    },
    SKUrl() {
      return WeChatAllJumpUrl(759, 34682)
    },
    isFromPm() {
      return this.$route.query.fromPage && this.$route.query.fromPage === 'pm'
    },
    showEmailSetting() {
      return this.newApplyForm.serviceStatusArr.indexOf(1) !== -1 || this.newApplyForm.serviceStatusArr.indexOf(5) !== -1
    },
    showWXSetting() {
      return this.newApplyForm.serviceStatusArr.indexOf(2) !== -1
    },
    showWechatCode() {
      return (
        this.showWXSetting ||
        (this.showEmailSetting &&
          (this.newApplyForm.emailTemplateStyleArr.indexOf(2) !== -1 || this.newApplyForm.emailTemplateStyleArr.indexOf(4) !== -1 || this.newApplyForm.emailTemplateStyleArr.indexOf(5) !== -1))
      )
    },
    showLogo() {
      return this.showWXSetting || (this.showEmailSetting && this.newApplyForm.emailTemplateStyleArr.indexOf(4) !== -1)
    },
    newApplyRules() {
      let rules = {}
      if (this.showEmailSetting && this.showWXSetting) {
        rules = this.newApplyRulesEmail4
      } else if (!this.showEmailSetting && this.showWXSetting) {
        rules = this.newApplyRulesWX
      } else if (this.showEmailSetting && !this.showWXSetting) {
        if (this.newApplyForm.emailTemplateStyleArr.indexOf(4) !== -1) {
          rules = this.newApplyRulesEmail3
        } else if (this.newApplyForm.emailTemplateStyleArr.indexOf(2) !== -1 || this.newApplyForm.emailTemplateStyleArr.indexOf(5) !== -1) {
          rules = this.newApplyRulesEmail2
        } else if (this.newApplyForm.emailTemplateStyleArr.indexOf(1) !== -1) {
          rules = this.newApplyRulesEmail1
        } else {
          rules = this.newApplyRulesEmail1
        }
      }
      return rules
    },
    uploadObj() {
      return {
        magId: this.newApplyForm.magId,
        type: 1
      }
    }
  },
  watch: {
    'newApplyForm.serviceStatusArr'(n) {
      this.nextStepRules.offUrl[0].required = n.indexOf(1) !== -1
    }
  },
  mounted() {
    if (this.dialogStatus === 'newApply') {
      this.initData()
      this.$nextTick(() => {
        this.$refs.newApplyForm.resetFields()
      })
    } else {
      this.getMagList()
    }
    if (this.isFromPm && sessionStorage.getItem('pmItem')) {
      let form = JSON.parse(sessionStorage.getItem('pmItem'))
      this.handleForm(form)
    }
    this.getOperateMemberList()
  },
  methods: {
    initData() {
      this.newApplyForm = {
        magName: '',
        magId: '',
        offUrl: '',
        magInfo: '',
        remark: '',
        templateType: '',
        leftWechatPicId: '',
        rightWechatPicId: '',
        leftWechatName: '',
        rightWechatPicName: '',
        logoPicId: '',
        wechatNewUrl: '',
        serviceStatusArr: [],
        serviceStatus: '',
        emailTemplateStyleArr: [],
        emailTemplateStyle: '',
        operateMemberName: ''
      }
      this.leftCodeCover = ''
      this.rightCodeCover = ''
      this.logoCover = ''
    },
    handleForm(form) {
      let serviceStatusArr = []
      let emailTemplateStyleArr = []
      let arr = []
      if (this.dialogStatus === 'add') {
        serviceStatusArr = [1, 2]
        this.active = 1
      } else if (form.serviceStatus == 0) {
        serviceStatusArr = [1, 2]
      } else if (form.serviceStatus) {
        serviceStatusArr = [form.serviceStatus]
      }
      arr = form.emailTemplateStyle ? form.emailTemplateStyle.split(',') : []
      if (arr.length > 0) {
        arr.map((item) => {
          emailTemplateStyleArr.push(Number(item))
        })
      }
      this.newApplyForm = { serviceStatusArr: serviceStatusArr, emailTemplateStyleArr: emailTemplateStyleArr, ...form }
    },
    // 获取期刊列表
    getMagList() {
      let params = {
        memberId: JSON.parse(sessionStorage.getItem('user')).id,
        size: 999,
        page: 0
      }
      GetMagList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.tableData = data.content
          this.tableData.map((item) => {
            if (item.id == this.$route.query.id) {
              this.handleForm(item)
            }
          })
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 期刊联想列表查询
    querySearchAsync(queryString, cb) {
      let params = {
        keyWords: queryString
      }
      GetQueryMagList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          cb(data)
          this.queryMagList = data
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    selectBlur() {
      setTimeout(() => {
        if (this.newApplyForm.magName) {
          let params = {
            keyWords: this.newApplyForm.magName
          }
          GetQueryMagList(params).then((res) => {
            if (res && res.data && res.data.status === 0) {
              const { data } = res.data
              this.queryMagList = data
              if (data.length > 0) {
                this.newApplyForm.magId = ''
                // console.log(this.newApplyForm.magName, 'this.newApplyForm.magName')
                data.map((item) => {
                  if (item.magName === this.newApplyForm.magName) {
                    // console.log(item, 'item')
                    this.newApplyForm.magId = item.magId
                    this.newApplyForm.magName = item.magName
                  }
                })
                // console.log(this.newApplyForm.magId, 'this.newApplyForm.magId')
                // setTimeout(() => {
                if (!this.newApplyForm.magId) {
                  this.$confirm('期刊库里暂未搜索到该刊，是否创建新刊?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  })
                    .then(() => {
                      this.newApplyForm.magName = this.newApplyForm.magName
                      this.newApplyForm.magId = ''
                    })
                    .catch(() => {
                      this.newApplyForm.magName = ''
                      this.newApplyForm.magId = ''
                      this.$message({
                        type: 'info',
                        message: '已取消创建'
                      })
                    })
                }
                // }, 500)
              } else if (data.length === 0) {
                this.$confirm('期刊库里暂未搜索到该刊，是否创建新刊?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(() => {
                    this.newApplyForm.magName = this.newApplyForm.magName
                    this.newApplyForm.magId = ''
                  })
                  .catch(() => {
                    this.newApplyForm.magName = ''
                    this.newApplyForm.magId = ''
                    this.$message({
                      type: 'info',
                      message: '已取消创建'
                    })
                  })
              }
            } else {
              const { message = '' } = res.data
              this.$message.error(message)
            }
          })
        } else {
        }
      }, 500)
    },
    selectMagList(item) {
      this.newApplyForm.magId = item.magId
      this.newApplyForm.magName = item.magName
      this.$refs.newApplyForm.validateField('magId')
      this.$refs.newApplyForm.validateField('magName')
    },
    // 成功上传左侧微信二维码
    handleLeftCodeSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.leftCodeCover = URL.createObjectURL(file.raw)
      this.newApplyForm.leftWechatPicId = ID
      this.$refs.newApplyForm.validateField('leftWechatPicId')
    },
    // 成功上传右侧微信二维码
    handleRightCodeSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.rightCodeCover = URL.createObjectURL(file.raw)
      this.newApplyForm.rightWechatPicId = ID
    },
    // 成功上传logo图
    handleLogoCodeSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.logoCover = URL.createObjectURL(file.raw)
      this.newApplyForm.logoPicId = ID
      this.$refs.newApplyForm.validateField('logoPicId')
    },
    // 查看样式
    showURL(type) {
      let idNode = ''
      if (type === 'yg') {
        idNode = 'qrcodeYG'
      } else if (type === 'sk') {
        idNode = 'qrcodeSK'
      }
      document.getElementById(idNode).innerHTML = ''
      let qrcode = new QRCode(idNode, {
        width: 120,
        height: 120,
        colorDark: '#000',
        colorLight: '#fff'
      })
      if (type === 'yg') {
        qrcode.makeCode(this.YGUrl)
      } else if (type === 'sk') {
        qrcode.makeCode(this.SKUrl)
      }
    },
    // 预览
    previewStyle(type) {
      if (type === 'yg') {
        window.open(this.YGUrl)
      } else if (type === 'sk') {
        window.open(this.SKUrl)
      }
    },
    // 下一步
    nextStep() {
      this.$refs.newApplyForm.validate((valid, object) => {
        if (!valid) {
          let message = Object.values(object)[0][0].message
          this.$message.error(message)
        } else {
          this.finishStatus = 'success'
          this.active = 1
          this.$nextTick(() => {
            this.$refs.newApplyForm.clearValidate()
          })
        }
      })
    },
    // 上一步
    lastStep() {
      this.finishStatus = 'success'
      this.active = 0
      this.$refs.newApplyForm.clearValidate()
    },
    // 提交
    submit(status) {
      let node = status === 'submit' ? 1 : status === 'save' ? 0 : ''
      this.newApplyForm.node = node
      if (status === 'save') {
        this.newEditInfo()
      } else {
        this.$refs.newApplyForm.validate((valid, object) => {
          if (!valid) {
            let message = Object.values(object)[0][0].message
            this.$message.error(message)
          } else {
            this.newEditInfo()
          }
        })
      }
    },
    newEditInfo() {
      this.newApplyForm.emailTemplateStyle = this.newApplyForm.emailTemplateStyleArr.join(',')
      this.newApplyForm.serviceStatus = this.newApplyForm.serviceStatusArr.length === 1 ? this.newApplyForm.serviceStatusArr[0] : 0
      let params = { ...this.newApplyForm }
      NewEditInfo(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          let message = this.newApplyForm.node === 1 ? '提交成功' : this.newApplyForm.node === 0 ? '保存成功' : ''
          this.$message.success(message)
          if (this.$refs.importFile) {
            this.$refs.importFile.submit()
          }
          this.$router.push({
            name: 'sales'
          })
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    toEmailUrl(num) {
      let url = ''
      if (num == 1) {
        url = 'http://www.das.publish.founderss.cn/das-api/vas/push/task/getTemplateHtml5Info?taskId=1423&pushHistoryTemplateId=645&nameSpace=www.das.publish.founderss.cn/das-api'
      } else if (num == 2) {
        url = 'http://www.das.publish.founderss.cn/das-api/vas/push/task/getTemplateHtml5Info?taskId=1332&pushHistoryTemplateId=523&nameSpace=www.das.publish.founderss.cn/das-api'
      } else if (num == 4) {
        url = 'http://www.das.publish.founderss.cn/das-api/vas/push/task/getTemplateHtml5Info?taskId=1428&pushHistoryTemplateId=650&nameSpace=www.das.publish.founderss.cn/das-api'
      } else if (num == 5) {
        url = 'http://www.das.publish.founderss.cn/das-api/vas/push/task/getTemplateHtml5Info?taskId=1450&pushHistoryTemplateId=703&nameSpace=www.das.publish.founderss.cn/das-api'
      }
      window.open(url, '_blank')
    },
    // 上传状态改变
    handleUploadChange(file, fileList) {
      const { status } = file
      if (status === 'success') {
        return
      }
      const { raw } = file
      const { type = '' } = raw
      if (type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.$message.warning('上传文件格式错误')
        return false
      }
      if (this.fileList.length > 0) {
        this.$confirm('当前已有导入模板，确认并导入新模板？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'btn-custom-cancel',
          type: 'warning'
        })
          .then(() => {
            this.fileList = [file]
            this.$refs.importFile.clearFiles()
            // const { name = '' } = file
            // this.$set(this.importData, 'importFileVal', name)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消导入'
            })
            return false
          })
      } else {
        this.fileList = [file]
        this.$refs.importFile.clearFiles()
        // const { name = '' } = file
        // this.$set(this.importData, 'importFileVal', name)
      }
    },
    // 导入模板成功回调
    handleUploadSuccess(res, file) {
      if (res && res.status === 0) {
        this.fileList = []
        // this.importData = {}
      } else {
        const { message = '' } = res
        this.$message.error(message)
      }
    },
    handleExportDownloadSample() {
      window.open(DownloadTemplate, '_self')
    },
    getOperateMemberList() {
      GetOperateMemberList().then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.operateMemberList = data
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    }
  }
}
</script>
<style lang="scss">
.newApply {
  .style-setting-item-img {
    img {
      object-fit: cover;
    }
  }
  .el-dialog__wrapper {
    padding: 0;
  }
  .el-step {
    flex-basis: 15% !important;
  }
  .el-textarea__inner {
    min-height: 120px !important;
  }
  .el-form-item--editor {
    .el-form-item__content {
      width: 850px;
    }
  }
  .code-item-text {
    width: 400px;
    display: block;
    input {
      width: 400px;
    }
  }
  .sales-apply-btns {
    .el-button + .el-button {
      margin-left: 20px;
    }
  }
  .emailStyleSetting {
    .el-checkbox-group {
      display: flex;
      .checkItem {
        margin-right: 20px;
        .el-button {
          display: block;
        }
      }
    }
  }
  .white-export {
    .upload-demo {
      display: inline-block;
    }
    a {
      margin-left: 20px;
    }
  }
}
.newApply-popper {
  text-align: center;
  img {
    margin: 10px auto;
  }
}
</style>
<style lang="scss" scoped>
.newApply {
  text-align: left;
  h3 {
    margin-top: 20px;
  }
  .emailStyleSetting {
    button {
      pointer-events: all;
    }
  }
  .download-sample {
    pointer-events: all;
  }
  .style-setting {
    display: flex;
    align-items: center;
    margin-top: 8px;
    .style-setting-item {
      margin-right: 40px;
      text-align: center;
      .style-setting-item-img {
        width: 100px;
        height: 100px;
        margin-top: 10px;
      }
      button {
        margin-top: 10px;
        pointer-events: all;
      }
    }
  }
  .code-box {
    display: flex;
    .code-item {
      img {
        width: 120px;
        height: 120px;
        object-fit: contain;
      }
      .upload-demo {
        width: 120px;
        height: 120px;
        border: 1px solid #f4f4f4;
        position: relative;
        .upload-btn {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 120px;
          height: 120px;
          opacity: 0;
        }
      }
    }
  }
  .history-link-img {
    width: 200px;
    margin-top: 10px;
  }
  .tip {
    color: #999999;
    font-size: 12px;
    line-height: 20px;
    margin: 10px 0;
  }
  .code-el-input {
    width: 600px;
  }
  .code-tip {
    margin-left: 190px;
  }
  .is-disabled {
    pointer-events: none;
  }
  .next-button {
    text-align: right;
  }
  .sales-apply-btns {
    text-align: right;
  }
}
</style>
